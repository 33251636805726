@charset "UTF-8";
.comp-root {
  height: 100vh;
  overflow-y: hidden;
}

.page-index .header .nav-bg {
  height: 664px;
}

@media (max-width: 1024px) {
  .page-index .header .nav-bg {
    height: 438px;
  }
}

@media (max-width: 767px) {
  .page-index .header .nav-bg {
    height: 292px;
  }
}

.page-index .header .nav-bg.active {
  height: 0;
}

#index_banner {
  position: relative;
}

#index_banner .line {
  width: 1px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  bottom: 0;
  z-index: 2;
  left: 7.91%;
}

@media screen and (max-width: 2328px) {
  #index_banner .line {
    height: 60%;
  }
}

@media screen and (max-width: 2048px) {
  #index_banner .line {
    height: 60%;
  }
}

@media screen and (max-width: 1920px) {
  #index_banner .line {
    height: 63%;
  }
}

@media screen and (max-width: 1680px) {
  #index_banner .line {
    height: 61%;
  }
}

@media screen and (max-width: 1680px) and (max-width: 1600px) {
  #index_banner .line {
    height: 62%;
  }
}

@media screen and (max-width: 1680px) and (max-width: 1366px) {
  #index_banner .line {
    height: 63%;
  }
}

@media screen and (max-width: 1680px) and (max-width: 1024px) {
  #index_banner .line {
    height: 65%;
  }
}

@media screen and (max-width: 1680px) and (max-width: 991px) {
  #index_banner .line {
    height: 69%;
  }
}

@media screen and (max-width: 1680px) and (max-width: 767px) {
  #index_banner .line {
    height: 68%;
  }
}

#index_banner .line::after {
  content: '';
  display: block;
  width: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  height: 49px;
}

@media (max-width: 1600px) {
  #index_banner .line::after {
    height: 45.83333px;
  }
}

@media (max-width: 1366px) {
  #index_banner .line::after {
    height: 42.66667px;
  }
}

@media (max-width: 1024px) {
  #index_banner .line::after {
    height: 39.5px;
  }
}

@media (max-width: 991px) {
  #index_banner .line::after {
    height: 36.33333px;
  }
}

@media (max-width: 767px) {
  #index_banner .line::after {
    height: 33.16667px;
  }
}

@media (max-width: 374px) {
  #index_banner .line::after {
    height: 30px;
  }
}

#index_banner .index-banner {
  position: relative;
  height: 100vh;
}

#index_banner .index-banner .swiper-wrapper .swiper-slide {
  overflow: hidden;
  display: flex;
  align-items: center;
}

#index_banner .index-banner .swiper-wrapper .swiper-slide.swiper-slide-active .slide-inner b, #index_banner .index-banner .swiper-wrapper .swiper-slide.swiper-slide-duplicate-active .slide-inner b {
  transform: scale(1);
}

#index_banner .index-banner .swiper-wrapper .swiper-slide .slide-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

#index_banner .index-banner .swiper-wrapper .swiper-slide .slide-inner::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
}

#index_banner .index-banner .swiper-wrapper .swiper-slide .slide-inner b {
  display: block;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 4s;
  transition-delay: .5s;
  transform: scale(1.1);
  transform-origin: center;
}

#index_banner .index-banner .swiper-wrapper .swiper-slide .slide-inner b.video-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
}

@media (max-width: 1024px) {
  #index_banner .index-banner .swiper-wrapper .swiper-slide .slide-inner b.video-img {
    display: block;
  }
}

#index_banner .index-banner .swiper-wrapper .swiper-slide .slide-inner video {
  display: block;
  height: 100%;
  width: 100%;
  transform: scale(1.3);
  transform-origin: center;
  display: block;
}

@media (max-width: 1024px) {
  #index_banner .index-banner .swiper-wrapper .swiper-slide .slide-inner video {
    display: none;
  }
}

#index_banner .index-banner .swiper-wrapper .swiper-slide .title {
  display: flex;
  position: absolute;
  left: 7.91%;
}

#index_banner .index-banner .swiper-wrapper .swiper-slide .title .right {
  margin-left: 40px;
}

#index_banner .index-banner .swiper-wrapper .swiper-slide .title .right p {
  width: 100%;
}

@media (max-width: 1600px) {
  #index_banner .index-banner .swiper-wrapper .swiper-slide .title .right p {
    width: 90%;
  }
}

@media (max-width: 1366px) {
  #index_banner .index-banner .swiper-wrapper .swiper-slide .title .right p {
    width: 80%;
  }
}

@media (max-width: 1024px) {
  #index_banner .index-banner .swiper-wrapper .swiper-slide .title .right p {
    width: 70%;
  }
}

#index_banner .index-banner .swiper-wrapper .swiper-slide .title .right p img {
  max-width: fit-content;
  width: 100%;
}

#index_banner .index-banner .swiper-wrapper .swiper-slide .title h2 {
  width: fit-content;
  font-family: DIN-Medium;
  font-size: 40px;
  background-image: linear-gradient(to right, #FFF4E5, #F4B975);
  background-clip: text;
  color: transparent;
  margin-bottom: 15px;
}

@media (max-width: 1600px) {
  #index_banner .index-banner .swiper-wrapper .swiper-slide .title h2 {
    font-size: 37.66667px;
  }
}

@media (max-width: 1366px) {
  #index_banner .index-banner .swiper-wrapper .swiper-slide .title h2 {
    font-size: 35.33333px;
  }
}

@media (max-width: 1024px) {
  #index_banner .index-banner .swiper-wrapper .swiper-slide .title h2 {
    font-size: 33px;
  }
}

@media (max-width: 991px) {
  #index_banner .index-banner .swiper-wrapper .swiper-slide .title h2 {
    font-size: 30.66667px;
  }
}

@media (max-width: 767px) {
  #index_banner .index-banner .swiper-wrapper .swiper-slide .title h2 {
    font-size: 28.33333px;
  }
}

@media (max-width: 374px) {
  #index_banner .index-banner .swiper-wrapper .swiper-slide .title h2 {
    font-size: 26px;
  }
}

#index_banner .index-banner .swiper-wrapper .swiper-slide .title .shubiao {
  color: #fff;
  display: flex;
  align-items: center;
}

#index_banner .index-banner .swiper-wrapper .swiper-slide .title .shubiao .rain-drop {
  box-sizing: border-box;
  width: 16px;
  height: 24px;
  border-radius: 15px;
  border: 1px solid #fff;
  background: rgba(0, 0, 0, 0);
  z-index: 1;
  overflow: hidden;
  transform: translate3d(-50%, 0, 0);
}

#index_banner .index-banner .swiper-wrapper .swiper-slide .title .shubiao .rain-drop span {
  display: block;
  width: 2px;
  height: 6px;
  border-radius: 5px;
  background: #fff;
  margin: 10px auto 0;
  animation: rain 1.5s infinite;
}

#index_banner .index-banner .swiper-wrapper .swiper-slide .title p {
  font-size: 12px;
}

#index_banner .swiper-button-prev, #index_banner .swiper-button-next {
  color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
  top: auto;
  bottom: 90px;
  width: 60px;
  height: 60px;
}

@media (max-width: 1600px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    bottom: 80px;
  }
}

@media (max-width: 1366px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    bottom: 70px;
  }
}

@media (max-width: 1024px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    bottom: 60px;
  }
}

@media (max-width: 991px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    bottom: 50px;
  }
}

@media (max-width: 767px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    bottom: 40px;
  }
}

@media (max-width: 374px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    bottom: 30px;
  }
}

@media (max-width: 1600px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    width: 55.66667px;
  }
}

@media (max-width: 1366px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    width: 51.33333px;
  }
}

@media (max-width: 1024px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    width: 47px;
  }
}

@media (max-width: 991px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    width: 42.66667px;
  }
}

@media (max-width: 767px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    width: 38.33333px;
  }
}

@media (max-width: 374px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    width: 34px;
  }
}

@media (max-width: 1600px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    height: 55.66667px;
  }
}

@media (max-width: 1366px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    height: 51.33333px;
  }
}

@media (max-width: 1024px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    height: 47px;
  }
}

@media (max-width: 991px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    height: 42.66667px;
  }
}

@media (max-width: 767px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    height: 38.33333px;
  }
}

@media (max-width: 374px) {
  #index_banner .swiper-button-prev, #index_banner .swiper-button-next {
    height: 34px;
  }
}

#index_banner .swiper-button-prev i, #index_banner .swiper-button-next i {
  font-size: 18px;
}

@media (max-width: 1600px) {
  #index_banner .swiper-button-prev i, #index_banner .swiper-button-next i {
    font-size: 17px;
  }
}

@media (max-width: 1366px) {
  #index_banner .swiper-button-prev i, #index_banner .swiper-button-next i {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  #index_banner .swiper-button-prev i, #index_banner .swiper-button-next i {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  #index_banner .swiper-button-prev i, #index_banner .swiper-button-next i {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  #index_banner .swiper-button-prev i, #index_banner .swiper-button-next i {
    font-size: 13px;
  }
}

@media (max-width: 374px) {
  #index_banner .swiper-button-prev i, #index_banner .swiper-button-next i {
    font-size: 12px;
  }
}

#index_banner .swiper-button-prev:after, #index_banner .swiper-button-next:after {
  content: normal;
}

#index_banner .swiper-button-prev:hover, #index_banner .swiper-button-next:hover {
  background-color: #fff;
  color: #c78f42;
}

#index_banner .swiper-button-prev {
  left: 10.52%;
}

@media (max-width: 1600px) {
  #index_banner .swiper-button-prev {
    left: 9.52%;
  }
}

@media (max-width: 767px) {
  #index_banner .swiper-button-prev {
    left: 66px;
  }
}

#index_banner .swiper-button-next {
  left: 14.58%;
  right: auto;
}

@media (max-width: 1366px) {
  #index_banner .swiper-button-next {
    left: 16%;
  }
}

@media (max-width: 767px) {
  #index_banner .swiper-button-next {
    left: 110px;
  }
}

#index_banner .swiper-pagination {
  right: 7.29%;
  z-index: 100;
  top: 316px;
}

@media (max-width: 1600px) {
  #index_banner .swiper-pagination {
    right: 8%;
  }
}

@media (max-width: 1366px) {
  #index_banner .swiper-pagination {
    right: 9%;
  }
}

@media (max-width: 1024px) {
  #index_banner .swiper-pagination {
    right: 7%;
  }
}

@media (max-width: 991px) {
  #index_banner .swiper-pagination {
    right: 9%;
  }
}

@media (max-width: 767px) {
  #index_banner .swiper-pagination {
    right: 10%;
  }
}

@media (max-width: 1024px) {
  #index_banner .swiper-pagination {
    top: 50%;
  }
}

#index_banner .swiper-pagination li.swiper-pagination-bullet {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: solid 1px transparent;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all .3s;
  background: transparent;
  opacity: 1;
}

#index_banner .swiper-pagination li span {
  opacity: 1;
  width: 6px;
  height: 6px;
  background-color: #fff;
  position: relative;
  border-radius: 50%;
  transition: all .3s;
  display: block !important;
}

#index_banner .swiper-pagination li:not(:last-child) {
  margin-bottom: 2px;
}

#index_banner .swiper-pagination li.swiper-pagination-bullet-active {
  border-color: #fff;
}

.index_news {
  height: 960px;
  flex-wrap: wrap;
  display: flex;
}

@media (max-width: 991px) {
  .index_news {
    height: fit-content;
  }
}

.index_news .left {
  width: 67.5%;
  background: #FEF7EE;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .index_news .left {
    width: 100%;
  }
}

.index_news .left .headline {
  width: 47.9%;
  height: 840px;
  background: #C78F42;
  padding-top: 107px;
  padding-bottom: 0px;
  padding-left: 8.48%;
  box-sizing: border-box;
}

@media (max-width: 991px) {
  .index_news .left .headline {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .index_news .left .headline {
    height: fit-content;
  }
}

@media (max-width: 991px) {
  .index_news .left .headline {
    padding-top: 30px;
  }
}

@media (max-width: 991px) {
  .index_news .left .headline {
    padding-bottom: 30px;
  }
}

.index_news .left .headline .pic {
  position: relative;
  overflow: hidden;
  padding-right: 0;
}

@media (max-width: 767px) {
  .index_news .left .headline .pic {
    padding-right: 30px;
  }
}

.index_news .left .headline .pic img {
  display: block;
  transition: all .3s;
  width: 100%;
}

.index_news .left .headline .pic:hover img {
  transform: scale(1.1);
}

.index_news .left .headline .pic .newsicon {
  position: absolute;
  top: 16px;
  left: 11px;
}

.index_news .left .headline .text {
  color: #ffffff;
  padding-right: 50px;
}

.index_news .left .headline .text .time {
  margin-top: 30px;
  font-family: DIN-Medium;
  font-size: 14px;
}

@media (max-width: 1600px) {
  .index_news .left .headline .text .time {
    font-size: 13.66667px;
  }
}

@media (max-width: 1366px) {
  .index_news .left .headline .text .time {
    font-size: 13.33333px;
  }
}

@media (max-width: 1024px) {
  .index_news .left .headline .text .time {
    font-size: 13px;
  }
}

@media (max-width: 991px) {
  .index_news .left .headline .text .time {
    font-size: 12.66667px;
  }
}

@media (max-width: 767px) {
  .index_news .left .headline .text .time {
    font-size: 12.33333px;
  }
}

@media (max-width: 374px) {
  .index_news .left .headline .text .time {
    font-size: 12px;
  }
}

.index_news .left .headline .text h2 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  margin-top: 12px;
  font-size: 22px;
  line-height: 1.8;
}

@media (max-width: 1600px) {
  .index_news .left .headline .text h2 {
    font-size: 21px;
  }
}

@media (max-width: 1366px) {
  .index_news .left .headline .text h2 {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .index_news .left .headline .text h2 {
    font-size: 19px;
  }
}

@media (max-width: 991px) {
  .index_news .left .headline .text h2 {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .index_news .left .headline .text h2 {
    font-size: 17px;
  }
}

@media (max-width: 374px) {
  .index_news .left .headline .text h2 {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index_news .left .headline .text h2 {
    line-height: 1.76667;
  }
}

@media (max-width: 1366px) {
  .index_news .left .headline .text h2 {
    line-height: 1.73333;
  }
}

@media (max-width: 1024px) {
  .index_news .left .headline .text h2 {
    line-height: 1.7;
  }
}

@media (max-width: 991px) {
  .index_news .left .headline .text h2 {
    line-height: 1.66667;
  }
}

@media (max-width: 767px) {
  .index_news .left .headline .text h2 {
    line-height: 1.63333;
  }
}

@media (max-width: 374px) {
  .index_news .left .headline .text h2 {
    line-height: 1.6;
  }
}

.index_news .left .headline .text p {
  margin-top: 30px;
  font-size: 16px;
  line-height: 2;
}

@media (max-width: 1600px) {
  .index_news .left .headline .text p {
    font-size: 15.66667px;
  }
}

@media (max-width: 1366px) {
  .index_news .left .headline .text p {
    font-size: 15.33333px;
  }
}

@media (max-width: 1024px) {
  .index_news .left .headline .text p {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .index_news .left .headline .text p {
    font-size: 14.66667px;
  }
}

@media (max-width: 767px) {
  .index_news .left .headline .text p {
    font-size: 14.33333px;
  }
}

@media (max-width: 374px) {
  .index_news .left .headline .text p {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index_news .left .headline .text p {
    line-height: 1.93333;
  }
}

@media (max-width: 1366px) {
  .index_news .left .headline .text p {
    line-height: 1.86667;
  }
}

@media (max-width: 1024px) {
  .index_news .left .headline .text p {
    line-height: 1.8;
  }
}

@media (max-width: 991px) {
  .index_news .left .headline .text p {
    line-height: 1.73333;
  }
}

@media (max-width: 767px) {
  .index_news .left .headline .text p {
    line-height: 1.66667;
  }
}

@media (max-width: 374px) {
  .index_news .left .headline .text p {
    line-height: 1.6;
  }
}

.index_news .left .headline a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  float: right;
  padding-bottom: 5px;
  width: 162px;
  margin-top: 50px;
  margin-right: 50px;
}

@media (max-width: 1600px) {
  .index_news .left .headline a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1366px) {
  .index_news .left .headline a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1024px) {
  .index_news .left .headline a {
    padding-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .index_news .left .headline a {
    padding-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .index_news .left .headline a {
    padding-bottom: 5px;
  }
}

@media (max-width: 374px) {
  .index_news .left .headline a {
    padding-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .index_news .left .headline a {
    margin-top: 20px;
  }
}

.index_news .left .headline a span {
  display: block;
  font-size: 14px;
  transition: all .3s;
}

.index_news .left .headline a i {
  display: block;
  transition: all .3s;
}

.index_news .left .headline a:before {
  content: '';
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  bottom: -5px;
}

.index_news .left .headline a:hover span {
  transform: translateX(5px);
}

.index_news .left .headline a:hover i {
  transform: translateX(-5px);
}

.index_news .left .news-list {
  width: 52.1%;
  box-sizing: border-box;
  margin-top: 170px;
  padding-bottom: 0px;
  padding-left: 6.48%;
  padding-right: 6.01%;
}

@media (max-width: 991px) {
  .index_news .left .news-list {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .index_news .left .news-list {
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
  .index_news .left .news-list {
    padding-bottom: 30px;
  }
}

.index_news .left .news-list .search-box {
  width: 100%;
  position: relative;
  z-index: 1;
}

.index_news .left .news-list .search-box input[type='text'] {
  display: block;
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  background: transparent;
  border-bottom: 1px solid #157574;
  padding-bottom: 10px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.6);
}

@media (max-width: 1600px) {
  .index_news .left .news-list .search-box input[type='text'] {
    font-size: 17.66667px;
  }
}

@media (max-width: 1366px) {
  .index_news .left .news-list .search-box input[type='text'] {
    font-size: 17.33333px;
  }
}

@media (max-width: 1024px) {
  .index_news .left .news-list .search-box input[type='text'] {
    font-size: 17px;
  }
}

@media (max-width: 991px) {
  .index_news .left .news-list .search-box input[type='text'] {
    font-size: 16.66667px;
  }
}

@media (max-width: 767px) {
  .index_news .left .news-list .search-box input[type='text'] {
    font-size: 16.33333px;
  }
}

@media (max-width: 374px) {
  .index_news .left .news-list .search-box input[type='text'] {
    font-size: 16px;
  }
}

.index_news .left .news-list .search-box input[type='text']::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.index_news .left .news-list .search-box input[type='text']:-moz-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.index_news .left .news-list .search-box input[type='text']::-moz-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.index_news .left .news-list .search-box input[type='text']:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.index_news .left .news-list .search-box button {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

.index_news .left .news-list .search-box button i {
  font-size: 22px;
}

.index_news .left .news-list ul li {
  margin-top: 60px;
}

@media (max-width: 991px) {
  .index_news .left .news-list ul li {
    margin-top: 20px;
  }
}

.index_news .left .news-list ul li a {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  margin-top: 15px;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.8);
  transition: all .3s;
  line-height: 1.8;
}

@media (max-width: 1600px) {
  .index_news .left .news-list ul li a {
    font-size: 21px;
  }
}

@media (max-width: 1366px) {
  .index_news .left .news-list ul li a {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .index_news .left .news-list ul li a {
    font-size: 19px;
  }
}

@media (max-width: 991px) {
  .index_news .left .news-list ul li a {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .index_news .left .news-list ul li a {
    font-size: 17px;
  }
}

@media (max-width: 374px) {
  .index_news .left .news-list ul li a {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index_news .left .news-list ul li a {
    line-height: 1.76667;
  }
}

@media (max-width: 1366px) {
  .index_news .left .news-list ul li a {
    line-height: 1.73333;
  }
}

@media (max-width: 1024px) {
  .index_news .left .news-list ul li a {
    line-height: 1.7;
  }
}

@media (max-width: 991px) {
  .index_news .left .news-list ul li a {
    line-height: 1.66667;
  }
}

@media (max-width: 767px) {
  .index_news .left .news-list ul li a {
    line-height: 1.63333;
  }
}

@media (max-width: 374px) {
  .index_news .left .news-list ul li a {
    line-height: 1.6;
  }
}

.index_news .left .news-list ul li a:hover {
  color: #157574;
}

.index_news .right {
  padding-top: 160px;
  padding-left: 4%;
  padding-right: 2.65625%;
  box-sizing: border-box;
  width: 32.5%;
  background: #fff;
}

@media (max-width: 1024px) {
  .index_news .right {
    padding-top: 30px;
  }
}

@media (max-width: 991px) {
  .index_news .right {
    width: 100%;
  }
}

.index_news .right .title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 55px;
}

.index_news .right .title h2 {
  font-size: 40px;
  color: #b07c35;
  font-weight: bold;
}

@media (max-width: 1600px) {
  .index_news .right .title h2 {
    font-size: 37.66667px;
  }
}

@media (max-width: 1366px) {
  .index_news .right .title h2 {
    font-size: 35.33333px;
  }
}

@media (max-width: 1024px) {
  .index_news .right .title h2 {
    font-size: 33px;
  }
}

@media (max-width: 991px) {
  .index_news .right .title h2 {
    font-size: 30.66667px;
  }
}

@media (max-width: 767px) {
  .index_news .right .title h2 {
    font-size: 28.33333px;
  }
}

@media (max-width: 374px) {
  .index_news .right .title h2 {
    font-size: 26px;
  }
}

.index_news .right .title a {
  font-size: 16px;
  position: relative;
  color: rgba(0, 0, 0, 0.6);
  transition: all .3s;
}

@media (max-width: 1600px) {
  .index_news .right .title a {
    font-size: 15.66667px;
  }
}

@media (max-width: 1366px) {
  .index_news .right .title a {
    font-size: 15.33333px;
  }
}

@media (max-width: 1024px) {
  .index_news .right .title a {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .index_news .right .title a {
    font-size: 14.66667px;
  }
}

@media (max-width: 767px) {
  .index_news .right .title a {
    font-size: 14.33333px;
  }
}

@media (max-width: 374px) {
  .index_news .right .title a {
    font-size: 14px;
  }
}

.index_news .right .title a:before, .index_news .right .title a:after {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: auto;
  bottom: -5px;
  height: 2px;
  background-color: #000;
  background-color: #000000;
  opacity: 0.3;
}

.index_news .right .title a:after {
  width: 0;
  left: auto;
  right: 0;
  opacity: 1;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: #fff;
}

.index_news .right .title a:hover {
  color: #b07c35;
}

.index_news .right .title a:hover:after {
  width: 100%;
  right: auto;
  left: 0;
}

.index_news .right ul li {
  margin-top: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
}

.index_news .right ul li .time {
  font-size: 16px;
  font-family: DIN-Medium;
  color: #157574;
}

@media (max-width: 1600px) {
  .index_news .right ul li .time {
    font-size: 15.66667px;
  }
}

@media (max-width: 1366px) {
  .index_news .right ul li .time {
    font-size: 15.33333px;
  }
}

@media (max-width: 1024px) {
  .index_news .right ul li .time {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .index_news .right ul li .time {
    font-size: 14.66667px;
  }
}

@media (max-width: 767px) {
  .index_news .right ul li .time {
    font-size: 14.33333px;
  }
}

@media (max-width: 374px) {
  .index_news .right ul li .time {
    font-size: 14px;
  }
}

.index_news .right ul li a {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1.8;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .index_news .right ul li a {
    font-size: 17.66667px;
  }
}

@media (max-width: 1366px) {
  .index_news .right ul li a {
    font-size: 17.33333px;
  }
}

@media (max-width: 1024px) {
  .index_news .right ul li a {
    font-size: 17px;
  }
}

@media (max-width: 991px) {
  .index_news .right ul li a {
    font-size: 16.66667px;
  }
}

@media (max-width: 767px) {
  .index_news .right ul li a {
    font-size: 16.33333px;
  }
}

@media (max-width: 374px) {
  .index_news .right ul li a {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index_news .right ul li a {
    line-height: 1.76667;
  }
}

@media (max-width: 1366px) {
  .index_news .right ul li a {
    line-height: 1.73333;
  }
}

@media (max-width: 1024px) {
  .index_news .right ul li a {
    line-height: 1.7;
  }
}

@media (max-width: 991px) {
  .index_news .right ul li a {
    line-height: 1.66667;
  }
}

@media (max-width: 767px) {
  .index_news .right ul li a {
    line-height: 1.63333;
  }
}

@media (max-width: 374px) {
  .index_news .right ul li a {
    line-height: 1.6;
  }
}

.index_news .right ul li a:hover {
  color: rgba(21, 117, 116, 0.8);
}

.index-about {
  margin-top: 120px;
}

@media (max-width: 1600px) {
  .index-about {
    margin-top: 105px;
  }
}

@media (max-width: 1366px) {
  .index-about {
    margin-top: 90px;
  }
}

@media (max-width: 1024px) {
  .index-about {
    margin-top: 75px;
  }
}

@media (max-width: 991px) {
  .index-about {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .index-about {
    margin-top: 45px;
  }
}

@media (max-width: 374px) {
  .index-about {
    margin-top: 30px;
  }
}

.index-about .cont1 {
  overflow: hidden;
  position: relative;
  padding-bottom: 190px;
  padding-left: 13.7%;
  padding-right: 18.75%;
}

@media (max-width: 991px) {
  .index-about .cont1 {
    padding-bottom: 0px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont1 {
    padding-left: 11.75%;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 {
    padding-left: 9.8%;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 {
    padding-left: 7.85%;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 {
    padding-left: 5.9%;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 {
    padding-left: 3.95%;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 {
    padding-left: 2%;
  }
}

@media (max-width: 1600px) {
  .index-about .cont1 {
    padding-right: 15.95833%;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 {
    padding-right: 13.16667%;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 {
    padding-right: 10.375%;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 {
    padding-right: 7.58333%;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 {
    padding-right: 4.79167%;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 {
    padding-right: 2%;
  }
}

.index-about .cont1 h1 {
  font-weight: bold;
  color: #157574;
  font-size: 40px;
  margin-bottom: 60px;
}

@media (max-width: 1600px) {
  .index-about .cont1 h1 {
    font-size: 37.66667px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 h1 {
    font-size: 35.33333px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 h1 {
    font-size: 33px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 h1 {
    font-size: 30.66667px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 h1 {
    font-size: 28.33333px;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 h1 {
    font-size: 26px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont1 h1 {
    margin-bottom: 53.33333px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 h1 {
    margin-bottom: 46.66667px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 h1 {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 h1 {
    margin-bottom: 33.33333px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 h1 {
    margin-bottom: 26.66667px;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 h1 {
    margin-bottom: 20px;
  }
}

.index-about .cont1 .cont {
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  display: flex;
}

@media (max-width: 991px) {
  .index-about .cont1 .cont {
    display: block;
  }
}

.index-about .cont1 .cont .left {
  flex-shrink: 0;
  line-height: 2;
  font-size: 18px;
  width: 50.7%;
}

@media (max-width: 1600px) {
  .index-about .cont1 .cont .left {
    line-height: 1.93333;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .cont .left {
    line-height: 1.86667;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .cont .left {
    line-height: 1.8;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .left {
    line-height: 1.73333;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .cont .left {
    line-height: 1.66667;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 .cont .left {
    line-height: 1.6;
  }
}

@media (max-width: 1600px) {
  .index-about .cont1 .cont .left {
    font-size: 17.33333px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .cont .left {
    font-size: 16.66667px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .cont .left {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .left {
    font-size: 15.33333px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .cont .left {
    font-size: 14.66667px;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 .cont .left {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .cont .left {
    width: 40%;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .left {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .left {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .cont .left {
    margin-bottom: 30px;
  }
}

.index-about .cont1 .cont .right {
  flex-shrink: 0;
  width: 50%;
  margin-left: 8%;
}

@media (max-width: 1600px) {
  .index-about .cont1 .cont .right {
    width: 60%;
  }
}

@media (max-width: 1380px) {
  .index-about .cont1 .cont .right {
    width: 80%;
  }
}

@media (max-width: 1370px) {
  .index-about .cont1 .cont .right {
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .cont .right {
    width: 75%;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right {
    margin-left: 0%;
  }
}

.index-about .cont1 .cont .right .num-cont {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.index-about .cont1 .cont .right .num-cont .num-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 50%;
  margin-bottom: 50px;
}

@media (max-width: 1366px) {
  .index-about .cont1 .cont .right .num-cont .num-item {
    width: 60%;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right .num-cont .num-item {
    width: 50%;
  }
}

.index-about .cont1 .cont .right .num-cont .num-item:nth-child(1), .index-about .cont1 .cont .right .num-cont .num-item:nth-child(3) {
  width: 50%;
}

@media (max-width: 1366px) {
  .index-about .cont1 .cont .right .num-cont .num-item:nth-child(1), .index-about .cont1 .cont .right .num-cont .num-item:nth-child(3) {
    width: 40%;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right .num-cont .num-item:nth-child(1), .index-about .cont1 .cont .right .num-cont .num-item:nth-child(3) {
    width: 50%;
  }
}

.index-about .cont1 .cont .right .num-cont .num-item .item-top {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 20px;
  position: relative;
}

@media (max-width: 1600px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top {
    margin-bottom: 18.33333px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top {
    margin-bottom: 16.66667px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top {
    margin-bottom: 15px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top {
    margin-bottom: 13.33333px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top {
    margin-bottom: 11.66667px;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top {
    margin-bottom: 10px;
  }
}

.index-about .cont1 .cont .right .num-cont .num-item .item-top .num {
  position: relative;
  color: #c78f42;
  line-height: 82px;
  font-size: 82px;
}

@media (max-width: 1600px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num {
    line-height: 73.33333px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num {
    line-height: 64.66667px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num {
    line-height: 56px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num {
    line-height: 47.33333px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num {
    line-height: 38.66667px;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num {
    line-height: 30px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num {
    font-size: 73.33333px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num {
    font-size: 64.66667px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num {
    font-size: 56px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num {
    font-size: 47.33333px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num {
    font-size: 38.66667px;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num {
    font-size: 30px;
  }
}

.index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
  overflow: hidden;
  display: inline-block;
  position: relative;
  line-height: 82px;
  height: 82px;
  font-size: 82px;
}

@media (max-width: 1600px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    line-height: 73.33333px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    line-height: 64.66667px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    line-height: 56px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    line-height: 47.33333px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    line-height: 38.66667px;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    line-height: 30px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    height: 73.33333px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    height: 64.66667px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    height: 56px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    height: 47.33333px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    height: 38.66667px;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    height: 30px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    font-size: 73.33333px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    font-size: 64.66667px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    font-size: 56px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    font-size: 47.33333px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    font-size: 38.66667px;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate {
    font-size: 30px;
  }
}

.index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dot {
  width: 21px;
  float: left;
  text-align: center;
}

.index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dom {
  text-align: center;
  float: left;
  position: relative;
  top: 0;
  width: 46px;
}

@media (max-width: 1600px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dom {
    width: 41px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dom {
    width: 36px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dom {
    width: 31px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dom {
    width: 26px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dom {
    width: 21px;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dom {
    width: 16px;
  }
}

.index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dom span, .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dot span {
  float: left;
  width: 100%;
  line-height: 1.1;
  height: 82px;
}

@media (max-width: 1600px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dom span, .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dot span {
    height: 73.33333px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dom span, .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dot span {
    height: 64.66667px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dom span, .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dot span {
    height: 56px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dom span, .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dot span {
    height: 47.33333px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dom span, .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dot span {
    height: 38.66667px;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dom span, .index-about .cont1 .cont .right .num-cont .num-item .item-top .num .number-animate .number-animate-dot span {
    height: 30px;
  }
}

.index-about .cont1 .cont .right .num-cont .num-item .item-top .dw {
  font-family: DIN-Medium;
  font-size: 50px;
  color: #c78f42;
  position: absolute;
  top: -25px;
  right: -35px;
}

@media (max-width: 1600px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .dw {
    font-size: 45px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .dw {
    font-size: 40px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .dw {
    font-size: 35px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .dw {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .dw {
    font-size: 25px;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .dw {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .dw {
    top: -15px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-top .dw {
    right: -25px;
  }
}

.index-about .cont1 .cont .right .num-cont .num-item .item-desc {
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  color: #115e5d;
}

@media (max-width: 1600px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-desc {
    font-size: 17.33333px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-desc {
    font-size: 16.66667px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-desc {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-desc {
    font-size: 15.33333px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-desc {
    font-size: 14.66667px;
  }
}

@media (max-width: 374px) {
  .index-about .cont1 .cont .right .num-cont .num-item .item-desc {
    font-size: 14px;
  }
}

.index-about .cont1 .right-img {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 0;
  display: block;
  width: 25.88%;
}

@media (max-width: 991px) {
  .index-about .cont1 .right-img {
    display: none;
  }
}

.index-about .cont1 .right-img img {
  width: 100%;
  height: auto;
  display: block;
}

.index-about .cont1 .center-img {
  position: absolute;
  bottom: 5%;
  right: 48%;
  z-index: 0;
  display: block;
  width: 9.3%;
}

@media (max-width: 991px) {
  .index-about .cont1 .center-img {
    display: none;
  }
}

.index-about .cont1 .center-img img {
  width: 100%;
  height: auto;
}

.index-about .cont2 {
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  padding-top: 105px;
  padding-bottom: 130px;
  padding-left: 13.7%;
  padding-right: 13%;
}

@media (max-width: 991px) {
  .index-about .cont2 {
    display: block;
  }
}

@media (max-width: 1600px) {
  .index-about .cont2 {
    padding-top: 92.5px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont2 {
    padding-top: 80px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont2 {
    padding-top: 67.5px;
  }
}

@media (max-width: 991px) {
  .index-about .cont2 {
    padding-top: 55px;
  }
}

@media (max-width: 767px) {
  .index-about .cont2 {
    padding-top: 42.5px;
  }
}

@media (max-width: 374px) {
  .index-about .cont2 {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont2 {
    padding-bottom: 113.33333px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont2 {
    padding-bottom: 96.66667px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont2 {
    padding-bottom: 80px;
  }
}

@media (max-width: 991px) {
  .index-about .cont2 {
    padding-bottom: 63.33333px;
  }
}

@media (max-width: 767px) {
  .index-about .cont2 {
    padding-bottom: 46.66667px;
  }
}

@media (max-width: 374px) {
  .index-about .cont2 {
    padding-bottom: 30px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont2 {
    padding-left: 11.75%;
  }
}

@media (max-width: 1366px) {
  .index-about .cont2 {
    padding-left: 9.8%;
  }
}

@media (max-width: 1024px) {
  .index-about .cont2 {
    padding-left: 7.85%;
  }
}

@media (max-width: 991px) {
  .index-about .cont2 {
    padding-left: 5.9%;
  }
}

@media (max-width: 767px) {
  .index-about .cont2 {
    padding-left: 3.95%;
  }
}

@media (max-width: 374px) {
  .index-about .cont2 {
    padding-left: 2%;
  }
}

@media (max-width: 1600px) {
  .index-about .cont2 {
    padding-right: 11.16667%;
  }
}

@media (max-width: 1366px) {
  .index-about .cont2 {
    padding-right: 9.33333%;
  }
}

@media (max-width: 1024px) {
  .index-about .cont2 {
    padding-right: 7.5%;
  }
}

@media (max-width: 991px) {
  .index-about .cont2 {
    padding-right: 5.66667%;
  }
}

@media (max-width: 767px) {
  .index-about .cont2 {
    padding-right: 3.83333%;
  }
}

@media (max-width: 374px) {
  .index-about .cont2 {
    padding-right: 2%;
  }
}

.index-about .cont2::after {
  content: '';
  display: block;
  height: 100%;
  background-color: #f9f0e4;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  width: 96.875%;
}

@media (max-width: 767px) {
  .index-about .cont2::after {
    width: 100%;
  }
}

.index-about .cont2 .left-img {
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  display: block;
  width: 36%;
  margin-top: -30%;
}

@media (max-width: 991px) {
  .index-about .cont2 .left-img {
    display: none;
  }
}

.index-about .cont2 .left-img img {
  display: block;
  width: 100%;
  height: auto;
}

.index-about .cont2 .left-img img.zhanwei {
  opacity: 0;
  visibility: hidden;
}

.index-about .cont2 .left-img img.wy {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
}

.index-about .cont2 .right-icon {
  flex-shrink: 0;
  width: 56.7%;
}

@media (max-width: 991px) {
  .index-about .cont2 .right-icon {
    width: 100%;
  }
}

.index-about .cont2 .right-icon ul {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}

.index-about .cont2 .right-icon ul li {
  flex-shrink: 0;
  text-align: center;
  width: 22%;
}

.index-about .cont2 .right-icon ul li:hover .item-img::after {
  opacity: 1;
}

.index-about .cont2 .right-icon ul li:hover .t {
  color: #c78f42;
}

.index-about .cont2 .right-icon ul li .item-img {
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  max-width: 155px;
  width: 100%;
  position: relative;
  margin-bottom: 40px;
}

@media (max-width: 1600px) {
  .index-about .cont2 .right-icon ul li .item-img {
    margin-bottom: 36.66667px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont2 .right-icon ul li .item-img {
    margin-bottom: 33.33333px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont2 .right-icon ul li .item-img {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .index-about .cont2 .right-icon ul li .item-img {
    margin-bottom: 26.66667px;
  }
}

@media (max-width: 767px) {
  .index-about .cont2 .right-icon ul li .item-img {
    margin-bottom: 23.33333px;
  }
}

@media (max-width: 374px) {
  .index-about .cont2 .right-icon ul li .item-img {
    margin-bottom: 20px;
  }
}

.index-about .cont2 .right-icon ul li .item-img::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(#fff, #fff, #f6ede1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: all .3s;
  opacity: 0;
}

.index-about .cont2 .right-icon ul li .item-img img {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
}

.index-about .cont2 .right-icon ul li .t {
  color: #157574;
  transition: all .3s;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .index-about .cont2 .right-icon ul li .t {
    font-size: 19.33333px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont2 .right-icon ul li .t {
    font-size: 18.66667px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont2 .right-icon ul li .t {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .index-about .cont2 .right-icon ul li .t {
    font-size: 17.33333px;
  }
}

@media (max-width: 767px) {
  .index-about .cont2 .right-icon ul li .t {
    font-size: 16.66667px;
  }
}

@media (max-width: 374px) {
  .index-about .cont2 .right-icon ul li .t {
    font-size: 16px;
  }
}

.index_pro {
  background-color: #157574;
  position: relative;
  overflow: hidden;
  display: block;
}

@media (max-width: 991px) {
  .index_pro {
    display: none;
  }
}

.index_pro.fixed .text-cont {
  position: fixed;
}

.index_pro.abs .text-cont {
  position: absolute;
  top: auto;
  bottom: -288px;
}

@media (max-width: 1600px) {
  .index_pro.abs .text-cont {
    bottom: -240px;
  }
}

@media (max-width: 1366px) {
  .index_pro.abs .text-cont {
    bottom: -192px;
  }
}

@media (max-width: 1024px) {
  .index_pro.abs .text-cont {
    bottom: -144px;
  }
}

@media (max-width: 991px) {
  .index_pro.abs .text-cont {
    bottom: -96px;
  }
}

@media (max-width: 767px) {
  .index_pro.abs .text-cont {
    bottom: -48px;
  }
}

@media (max-width: 374px) {
  .index_pro.abs .text-cont {
    bottom: 0px;
  }
}

.index_pro .title {
  padding-top: 130px;
  margin-left: 6.45%;
}

.index_pro .title h2 {
  font-weight: bold;
  font-size: 40px;
  color: #fff;
}

@media (max-width: 1600px) {
  .index_pro .title h2 {
    font-size: 37.66667px;
  }
}

@media (max-width: 1366px) {
  .index_pro .title h2 {
    font-size: 35.33333px;
  }
}

@media (max-width: 1024px) {
  .index_pro .title h2 {
    font-size: 33px;
  }
}

@media (max-width: 991px) {
  .index_pro .title h2 {
    font-size: 30.66667px;
  }
}

@media (max-width: 767px) {
  .index_pro .title h2 {
    font-size: 28.33333px;
  }
}

@media (max-width: 374px) {
  .index_pro .title h2 {
    font-size: 26px;
  }
}

.index_pro .text-cont {
  height: 100vh;
  position: absolute;
  z-index: 1;
  color: #fff;
  top: 288px;
  width: 28.33%;
  right: 130px;
}

@media (max-width: 1600px) {
  .index_pro .text-cont {
    top: 240px;
  }
}

@media (max-width: 1366px) {
  .index_pro .text-cont {
    top: 192px;
  }
}

@media (max-width: 1024px) {
  .index_pro .text-cont {
    top: 144px;
  }
}

@media (max-width: 991px) {
  .index_pro .text-cont {
    top: 96px;
  }
}

@media (max-width: 767px) {
  .index_pro .text-cont {
    top: 48px;
  }
}

@media (max-width: 374px) {
  .index_pro .text-cont {
    top: 0px;
  }
}

@media (max-width: 1366px) {
  .index_pro .text-cont {
    width: 32%;
  }
}

@media (max-width: 1600px) {
  .index_pro .text-cont {
    right: 100px;
  }
}

@media (max-width: 1366px) {
  .index_pro .text-cont {
    right: 60px;
  }
}

@media (max-width: 1024px) {
  .index_pro .text-cont {
    right: 40px;
  }
}

@media (max-width: 991px) {
  .index_pro .text-cont {
    right: 20px;
  }
}

.index_pro .text-cont .pro-page {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 46px;
  overflow: hidden;
}

@media (max-width: 1600px) {
  .index_pro .text-cont .pro-page {
    height: 46px;
  }
}

.index_pro .text-cont .pro-page::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleY(0.5);
}

.index_pro .text-cont .pro-page .title-line {
  position: absolute;
  display: block;
  top: 44px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
}

@media (max-width: 1600px) {
  .index_pro .text-cont .pro-page .title-line {
    top: 44px;
  }
}

.index_pro .text-cont .pro-page .page-title {
  transition: all .3s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.index_pro .text-cont .pro-page h2 {
  font-weight: bold;
  font-size: 22px;
  padding-bottom: 10px;
  padding-top: 10px;
}

@media (max-width: 1600px) {
  .index_pro .text-cont .pro-page h2 {
    font-size: 21.33333px;
  }
}

@media (max-width: 1366px) {
  .index_pro .text-cont .pro-page h2 {
    font-size: 20.66667px;
  }
}

@media (max-width: 1024px) {
  .index_pro .text-cont .pro-page h2 {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .index_pro .text-cont .pro-page h2 {
    font-size: 19.33333px;
  }
}

@media (max-width: 767px) {
  .index_pro .text-cont .pro-page h2 {
    font-size: 18.66667px;
  }
}

@media (max-width: 374px) {
  .index_pro .text-cont .pro-page h2 {
    font-size: 18px;
  }
}

.index_pro .text-cont .pro-page .line {
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  bottom: -1px;
}

.index_pro .text-cont .pro-page .nth {
  font-family: DIN-Medium;
  font-size: 20px;
  color: #fffefe;
  display: flex;
  margin-top: 10px;
}

@media (max-width: 1600px) {
  .index_pro .text-cont .pro-page .nth {
    font-size: 19.33333px;
  }
}

@media (max-width: 1366px) {
  .index_pro .text-cont .pro-page .nth {
    font-size: 18.66667px;
  }
}

@media (max-width: 1024px) {
  .index_pro .text-cont .pro-page .nth {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .index_pro .text-cont .pro-page .nth {
    font-size: 17.33333px;
  }
}

@media (max-width: 767px) {
  .index_pro .text-cont .pro-page .nth {
    font-size: 16.66667px;
  }
}

@media (max-width: 374px) {
  .index_pro .text-cont .pro-page .nth {
    font-size: 16px;
  }
}

.index_pro .text-cont .pro-page .nth .first {
  transition: all .3s;
}

.index_pro .text-cont .pro-page .nth .first span {
  display: block;
  height: 50px;
}

.index_pro .text-cont .pro-page .nth .second span {
  opacity: .2;
}

.index_pro .text-cont .pro-page .nth .third span {
  opacity: .2;
}

.index_pro .text-cont .item {
  width: 100%;
  position: absolute;
  transition: all .3s;
  color: #fff;
  top: 130px;
  opacity: 0;
  visibility: hidden;
}

.index_pro .text-cont .item h2 {
  line-height: 1.6;
  margin-bottom: 20px;
  font-size: 28px;
}

@media (max-width: 1600px) {
  .index_pro .text-cont .item h2 {
    font-size: 27px;
  }
}

@media (max-width: 1366px) {
  .index_pro .text-cont .item h2 {
    font-size: 26px;
  }
}

@media (max-width: 1024px) {
  .index_pro .text-cont .item h2 {
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  .index_pro .text-cont .item h2 {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .index_pro .text-cont .item h2 {
    font-size: 23px;
  }
}

@media (max-width: 374px) {
  .index_pro .text-cont .item h2 {
    font-size: 22px;
  }
}

.index_pro .text-cont .item p {
  font-size: 16px;
  line-height: 2;
}

@media (max-width: 1600px) {
  .index_pro .text-cont .item p {
    font-size: 15.66667px;
  }
}

@media (max-width: 1366px) {
  .index_pro .text-cont .item p {
    font-size: 15.33333px;
  }
}

@media (max-width: 1024px) {
  .index_pro .text-cont .item p {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .index_pro .text-cont .item p {
    font-size: 14.66667px;
  }
}

@media (max-width: 767px) {
  .index_pro .text-cont .item p {
    font-size: 14.33333px;
  }
}

@media (max-width: 374px) {
  .index_pro .text-cont .item p {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index_pro .text-cont .item p {
    line-height: 1.93333;
  }
}

@media (max-width: 1366px) {
  .index_pro .text-cont .item p {
    line-height: 1.86667;
  }
}

@media (max-width: 1024px) {
  .index_pro .text-cont .item p {
    line-height: 1.8;
  }
}

@media (max-width: 991px) {
  .index_pro .text-cont .item p {
    line-height: 1.73333;
  }
}

@media (max-width: 767px) {
  .index_pro .text-cont .item p {
    line-height: 1.66667;
  }
}

@media (max-width: 374px) {
  .index_pro .text-cont .item p {
    line-height: 1.6;
  }
}

.index_pro .text-cont .item a {
  float: right;
  margin-top: 55px;
  display: block;
  width: 300px;
  height: 93px;
  background-color: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  color: #157574;
  font-size: 14px;
}

@media (max-width: 1600px) {
  .index_pro .text-cont .item a {
    font-size: 13.66667px;
  }
}

@media (max-width: 1366px) {
  .index_pro .text-cont .item a {
    font-size: 13.33333px;
  }
}

@media (max-width: 1024px) {
  .index_pro .text-cont .item a {
    font-size: 13px;
  }
}

@media (max-width: 991px) {
  .index_pro .text-cont .item a {
    font-size: 12.66667px;
  }
}

@media (max-width: 767px) {
  .index_pro .text-cont .item a {
    font-size: 12.33333px;
  }
}

@media (max-width: 374px) {
  .index_pro .text-cont .item a {
    font-size: 12px;
  }
}

.index_pro .text-cont .item a span {
  display: block;
  transition: all .3s;
}

.index_pro .text-cont .item a i {
  transition: all .3s;
  font-size: 36px;
  position: absolute;
  bottom: 0;
  right: 20px;
}

.index_pro .text-cont .item a:hover span {
  transform: translateX(10px);
}

.index_pro .text-cont .item a:hover i {
  transform: translateX(-10px);
}

.index_pro .text-cont .item.active {
  opacity: 1;
  visibility: visible;
}

.index_pro .img-cont {
  width: 51.98%;
  margin-left: 6.45%;
}

.index_pro .img-cont .item {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.index_pro .img-cont .item .pic-title {
  z-index: 1;
  left: 7%;
  position: absolute;
  bottom: -50%;
  font-family: DIN-Medium;
  text-transform: uppercase;
  font-size: 105px;
  color: #fff;
  line-height: .8;
}

@media (max-width: 1600px) {
  .index_pro .img-cont .item .pic-title {
    font-size: 90.83333px;
  }
}

@media (max-width: 1366px) {
  .index_pro .img-cont .item .pic-title {
    font-size: 76.66667px;
  }
}

@media (max-width: 1024px) {
  .index_pro .img-cont .item .pic-title {
    font-size: 62.5px;
  }
}

@media (max-width: 991px) {
  .index_pro .img-cont .item .pic-title {
    font-size: 48.33333px;
  }
}

@media (max-width: 767px) {
  .index_pro .img-cont .item .pic-title {
    font-size: 34.16667px;
  }
}

@media (max-width: 374px) {
  .index_pro .img-cont .item .pic-title {
    font-size: 20px;
  }
}

.index_pro .img-cont .item .pic-title h2 {
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #fff;
}

.index_pro .img-cont .item .pic-title h2, .index_pro .img-cont .item .pic-title h3 {
  overflow: hidden;
  display: flex;
}

.index_pro .img-cont .item .pic-title h2 span, .index_pro .img-cont .item .pic-title h3 span {
  display: inline-block;
  transition: transform 0.2s cubic-bezier(0, 0, 0.22, 0.85);
  transform: translateY(100%);
}

.index_pro .img-cont .item .pic-title.show h2 span, .index_pro .img-cont .item .pic-title.show h3 span {
  transform: translateY(0);
}

.index_pro .img-cont .item .img {
  width: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  background-color: #000;
}

.index_pro .img-cont .item .img img {
  width: 100%;
  height: auto;
  display: block;
  opacity: .8;
}

.index_pro-mod {
  display: none;
  background-color: #157574;
}

@media (max-width: 991px) {
  .index_pro-mod {
    display: block;
  }
}

.index_pro-mod .title {
  padding-top: 50px;
  margin-bottom: 30px;
  margin-left: 6.45%;
}

.index_pro-mod .title h2 {
  font-weight: bold;
  font-size: 40px;
  color: #fff;
}

@media (max-width: 1600px) {
  .index_pro-mod .title h2 {
    font-size: 37.66667px;
  }
}

@media (max-width: 1366px) {
  .index_pro-mod .title h2 {
    font-size: 35.33333px;
  }
}

@media (max-width: 1024px) {
  .index_pro-mod .title h2 {
    font-size: 33px;
  }
}

@media (max-width: 991px) {
  .index_pro-mod .title h2 {
    font-size: 30.66667px;
  }
}

@media (max-width: 767px) {
  .index_pro-mod .title h2 {
    font-size: 28.33333px;
  }
}

@media (max-width: 374px) {
  .index_pro-mod .title h2 {
    font-size: 26px;
  }
}

.index_pro-mod ul {
  padding-bottom: 30px;
}

.index_pro-mod ul li {
  margin-bottom: 30px;
}

.index_pro-mod ul li:last-child {
  margin-bottom: 0;
}

.index_pro-mod ul li .pic {
  width: 90%;
  position: relative;
  margin: auto;
}

.index_pro-mod ul li .pic img {
  display: block;
  width: 100%;
  max-width: fit-content;
}

.index_pro-mod ul li .pic .pic-title {
  transition: all 1.5s;
  left: 7%;
  position: absolute;
  bottom: 0;
  font-family: DIN-Medium;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 26px;
  color: #fff;
}

.index_pro-mod ul li .pic .pic-title h2 {
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #fff;
}

.index_pro-mod ul li .text {
  width: 90%;
  margin: auto;
}

.index_pro-mod ul li .text .text-title {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  border-bottom: 1px solid #fff;
}

.index_pro-mod ul li .text .text-title h2 {
  font-size: 22px;
  padding-bottom: 10px;
  border-bottom: 2px solid #fff;
}

.index_pro-mod ul li .text .content {
  color: #fff;
}

.index_pro-mod ul li .text .content h2 {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 1.6;
}

.index_pro-mod ul li .text .content p {
  font-size: 14px;
  line-height: 1.6;
}

.index_pro-mod ul li .text .content a {
  margin-top: 20px;
  float: right;
  display: block;
  width: 150px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: #157574;
  position: relative;
  font-size: 14px;
}

@media (max-width: 1600px) {
  .index_pro-mod ul li .text .content a {
    font-size: 13.66667px;
  }
}

@media (max-width: 1366px) {
  .index_pro-mod ul li .text .content a {
    font-size: 13.33333px;
  }
}

@media (max-width: 1024px) {
  .index_pro-mod ul li .text .content a {
    font-size: 13px;
  }
}

@media (max-width: 991px) {
  .index_pro-mod ul li .text .content a {
    font-size: 12.66667px;
  }
}

@media (max-width: 767px) {
  .index_pro-mod ul li .text .content a {
    font-size: 12.33333px;
  }
}

@media (max-width: 374px) {
  .index_pro-mod ul li .text .content a {
    font-size: 12px;
  }
}

.index_pro-mod ul li .text .content a i {
  font-size: 30px;
  position: absolute;
  bottom: 0;
  right: 20px;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #157574;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.loading .cont {
  position: relative;
  z-index: 10;
  color: #fff;
  margin-bottom: 200px;
}

@media (max-width: 1600px) {
  .loading .cont {
    margin-bottom: 183.33333px;
  }
}

@media (max-width: 1366px) {
  .loading .cont {
    margin-bottom: 166.66667px;
  }
}

@media (max-width: 1024px) {
  .loading .cont {
    margin-bottom: 150px;
  }
}

@media (max-width: 991px) {
  .loading .cont {
    margin-bottom: 133.33333px;
  }
}

@media (max-width: 767px) {
  .loading .cont {
    margin-bottom: 116.66667px;
  }
}

@media (max-width: 374px) {
  .loading .cont {
    margin-bottom: 100px;
  }
}

.loading .cont p {
  margin-top: 45px;
}

@media (max-width: 1600px) {
  .loading .cont p {
    margin-top: 40px;
  }
}

@media (max-width: 1366px) {
  .loading .cont p {
    margin-top: 35px;
  }
}

@media (max-width: 1024px) {
  .loading .cont p {
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
  .loading .cont p {
    margin-top: 25px;
  }
}

@media (max-width: 767px) {
  .loading .cont p {
    margin-top: 20px;
  }
}

@media (max-width: 374px) {
  .loading .cont p {
    margin-top: 15px;
  }
}

.loading .cont p span {
  display: inline-block;
  height: 1em;
  line-height: 1;
  text-align: left;
  vertical-align: -0.25em;
  /* 属性设置元素的垂直对齐方式。指定为负长度，可以使元素降低 */
  overflow: hidden;
  margin-left: 5px;
}

.loading .cont p span::before {
  display: block;
  content: '...\A..\A.';
  white-space: pre-wrap;
  /* 保留空白符序列 */
  animation: span 3s infinite step-start both;
}

@keyframes span {
  33% {
    transform: translateY(-2em);
  }
  66% {
    transform: translateY(-1em);
  }
}

.loading #bg_box {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  opacity: .6;
}
